import React from 'react';
import  { useContext } from 'react';
import { Context } from '../../Provider';
import ProductCard from '../../components/ProductCard/ProductCard';
import './CategoryPageStyle.css'
import image1 from './images/image-1.jpg';
import image2 from './images/image-2.jpg';
import image3 from './images/image-3.jfif';
import { Link } from 'react-router-dom'; 
import { FaArrowLeft } from 'react-icons/fa'; 


const images = [
  image1,
  image2,
  image3,
];


const CategoryPage = ({ categoryName }) => {

  // const { categories, loading, error } = useContext(Context);
  const { addToCart } = useContext(Context);
  // const products = categories[categoryName] || [];
  const products = [
    {
      id: 1,
      name: "Vanilla Scented Candle",
      price: "12.99",
      images: images
    },
    {
      id: 2,
      name: "Lavender Aromatherapy Candle",
      price: "15.49",
      images: images
    },
    {
      id: 3,
      name: "Cinnamon Spice Candle",
      price: "11.99",
      images: images
    },
    {
      id: 4,
      name: "Rose Petal Soy Candle",
      price: "14.99",
      images: images
    },
    {
      id: 5,
      name: "Ocean Breeze Candle",
      price: "13.49",
      images: images
    }
  ];
  
  
  
  // const handleAddToCart =() => {
  //   alert('added to cart')
  // }

  // if (loading) {
  //   return <p>Loading products...</p>;
  // }

  // if (error) {
  //   return <p>{error}</p>;
  // }

  return (
    <div className="category-page">
       <Link to="/shop" className="back-to-categories">
        <FaArrowLeft /> Back to Categories
      </Link>
      <h1>{categoryName} Products</h1>
      <p>Explore our exclusive range of candles.</p>
      
      <div className="product-grid" data-aos='fade-up'>
      
        {products.length > 0 ? (
          products.map((product,index) => (
            <ProductCard
                key={index}
                name={product.name}
                price={product.price}
                images={product.images}
                onAddToCart={()=> addToCart(product) }
                isInCartPage={false}
              /> 
          ))
        ) : (
          <p>No products found in this category.</p>
        )}      
        </div>
    </div>
  );
};

export default CategoryPage;
