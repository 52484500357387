import React from 'react';
import './ShopPageStyle.css';
import { Link } from 'react-router-dom'; 
import image1 from './images/category-1.jpg';
import image2 from './images/category-2.jpg';
import image3 from './images/category-3.jpg';
import image4 from './images/category-4.jpg';

function ShopPage() {
  const categories = [
    { name: "category-1", imageUrl: image1 },
    { name: "category-2", imageUrl: image2 },
    { name: "category-3", imageUrl: image3 },
    { name: "category-4", imageUrl: image4 }
  ];

  return (
    <div className="shop-page-container">
      <h1>Explore Our Candle Collections</h1>
      <div className="categories-grid">
        {categories.map((category, index) => (
          <Link to={`/${category.name}`} key={index} className="category-card">
            <img src={category.imageUrl} alt={category.name} className="category-image" />
            <div className="category-overlay">
              <h2>{category.name}</h2>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default ShopPage;
