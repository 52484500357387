import React, { createContext, useState, useEffect } from 'react';

export const Context = createContext();

export const Provider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  const [categories, setCategories] = useState({});
  const [allProducts] = useState([]);
  const [loading] = useState(true);
  const [error] = useState(null);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (product) => {
    setCart((prevCart) => {
      const updatedCart = [...prevCart, product];
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  const removeFromCart = (productId) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.filter(
        (product) => product.id !== productId
      );
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      console.log("Item removed", updatedCart)
      return updatedCart;
    });
  };

  const setCategoryProducts = (category, products) => {
    setCategories((prevCategories) => ({
      ...prevCategories,
      [category]: products,
    }));
  };

  return (
    <Context.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        categories,
        setCategoryProducts,
        allProducts,
        loading,
        error,
      }}
    >
      {children}
    </Context.Provider>
  );
};
