import React from 'react';
import './HomePageStyle.css'; 
import homeImage from './images/Flames.jpeg'; 
import HeroSection from '../../components/HeroSectoin/HeroSection';
function HomePage() {
  return (
    
    <div className="homepage-container ">
      
      <img src={homeImage} alt="Home" className="responsive-image" />
      
      <h1>Home Page</h1>
      <p>Welcome to the home page!</p>
        
      
      <HeroSection/>
    </div>
  );
}

export default HomePage;
