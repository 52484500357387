import React, { useContext ,useState,useEffect} from 'react';
import { Context } from '../../Provider';
import ProductCard from '../../components/ProductCard/ProductCard'; 
import './CartPageStyle.css'; 

function CartPage() {
  const { cart , removeFromCart} = useContext(Context); 
  const [totalPrice,setTotalPrice] =useState( cart.reduce((total, item) => total + parseFloat(item.price), 0)) 
  
  
  useEffect(() => {
    setTotalPrice( cart.reduce((total, item) => total + parseFloat(item.price), 0))
  }, [cart]);

  return (
    <div className="cart-page-container">
      <h1 className="cart-page-title">Cart Page</h1>
      <div className="cart-items-container">
        {cart.length > 0 ? (
          cart.map((item, index) => (
            <ProductCard
              key={index}
              name={item.name}
              price={item.price}
              images={item.images}
              isInCartPage={true}
              removeFromCart={()=> removeFromCart(item.id)}
            />
          ))
        ) : (
          <p className="cart-empty-message">Your cart is empty.</p>
        )}
      </div>

      
      {cart.length > 0 && (
        <div className="cart-total">
          <h2>Total Price: ${totalPrice.toFixed(2)}</h2>
        </div>
      )}
    </div>
  );
}

export default CartPage;
