import React, { useState,useContext } from 'react';
import { FaHome, FaShoppingCart, FaBars } from 'react-icons/fa';
import { FaShop } from "react-icons/fa6";
import './NavbarStyle.css';
import { Context } from '../../Provider';
import { Badge } from '@mui/material';


function Navbar() {
  const {cart } = useContext(Context)
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="navbar">
      <div className="navbar-brand">
        <button className="sidebar-toggle" onClick={toggleSidebar}>
          <FaBars />
        </button>
        <h1>Flames</h1>
      </div>
      <div className="navbar-links">
        <a href="/" className="navbar-link">
          <FaHome />  Home
        </a>
        <a href="/shop" className="navbar-link">
        <FaShop />  Shop
        </a>
        <a href="/cart" className="navbar-link">
          <div>
          <Badge badgeContent={cart.length} color='error' anchorOrigin={{ vertical: 'top',horizontal: 'left',}}>
          <FaShoppingCart />
           Cart
          </Badge>
          </div>
        </a>
      </div>

  
      <div className={`sidebar-overlay ${sidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}></div>

     
      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <h2>Categories</h2>
          <button className="sidebar-close" onClick={toggleSidebar}>
            &times;
          </button>
        </div>
        <ul className="sidebar-menu">
          <li><a href="/Category-1">Category 1</a></li>
          <li><a href="/Category-2">Category 2</a></li>
          <li><a href="/Category-3">Category 3</a></li>
          <li><a href="/Category-4">Category 4</a></li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
