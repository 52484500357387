import React from 'react';
import './FooterStyle.css';
import { FaInstagram } from 'react-icons/fa'; // Import Instagram icon

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>Follow us on Instagram </p>
        <a href="https://www.instagram.com/flames_light_your_mood" target="_blank" rel="noopener noreferrer" className="footer-link">
          <FaInstagram className="footer-icon" />
          Flames
        </a>
      </div>
    </footer>
  );
}

export default Footer;
