import React from 'react';
import { useEffect } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './App.css';
import { Provider } from './Provider';
import Navbar from './components/Navbar/Navbar';
import CartPage from './pages/CartPage/CartPage';
import HomePage from './pages/HomePage/HomePage';
import CategoryPage from './pages/CategoryPage/CategoryPage';
import ShopPage from './pages/StorePage/ShopPage';
import Footer from './components/Footer/Footer';

function App() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <Provider>
      <div className="App" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="2000">
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Navbar />
          
        
          <div className="content-wrapper">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/shop" element={<ShopPage />} />
              <Route path="/category-1" element={<CategoryPage categoryName={"category 1"} />} />
              <Route path="/category-2" element={<CategoryPage categoryName={"category 2"} />} />
              <Route path="/category-3" element={<CategoryPage categoryName={"category 3"} />} />
              <Route path="/category-4" element={<CategoryPage categoryName={"category 4"} />} />
            </Routes>
          </div>
          
          
          <Footer />
        </BrowserRouter>
      </div>
    </Provider>
  );
}

export default App;
