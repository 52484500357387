import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function ImagesCarousel({ name ,images}) {
  return (
   
      <Carousel 
            useKeyboardArrows={true}
            showIndicators
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true} >
        {images.map((URL, index) => (
          <div className="slide" key={index}>
            <img alt={name} src={URL} key={index} />
          </div>
        ))}
      </Carousel>
   
  );
}
export default ImagesCarousel;
