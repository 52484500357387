import React from 'react';
import './ProductCardStyle.css'; 
import ImagesCarousel from '../ImagesCarousel/ImagesCarousel';

function ProductCard({ name, price, onAddToCart, removeFromCart, images, isInCartPage }) {
  return (
    <div className="product-card" data-aos="fade-up">
      <div className='product-image'>
        <ImagesCarousel name={name} images={images} />
      </div>
      <h3 className="product-name">{name}</h3>
      <p className="product-price">₪{price}</p>
      <button className="cart-btn"
        onClick={isInCartPage ? removeFromCart : onAddToCart}
      >
        {isInCartPage ? 'Remove Product' : 'Add to Cart'}
      </button>
    </div>
  );
}

export default ProductCard;
