import React from 'react';
import './HeroSectionStyle.css'; 
import backgroundImage from './candle.jpg'; 

function HeroSection() {
  return (
    
      <div className="hero-section" style={{ backgroundImage: `url(${backgroundImage})` }} >
        <div className="hero-overlay">
          <h1 className="hero-title">Light Up Your Space with Our Handcrafted Candles</h1>
          <a href="/shop" className="cta-button">Shop Now</a>
        </div>
      </div>
   
  );
}

export default HeroSection;

